<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="data-container">
          <div class="data-item">
            <div class="data-label">Region:</div>
            <div class="data-value">{{ region }}</div>
          </div>
          <div class="data-item">
            <div class="data-label">Endpoint:</div>
            <div class="data-value">{{ endpoint }}</div>
          </div>
          <div class="data-item">
            <div class="data-label">Password:</div>
            <div class="data-value">{{ password }}</div>
          </div>
          <div class="data-item">
            <div class="data-label">Port:</div>
            <div class="data-value">{{ port }}</div>
          </div>
          <div class="data-item">
            <div class="data-label">TLS/SSL:</div>
            <div class="data-value">{{ tlsEnabled ? 'Enabled' : 'Disabled' }}</div>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Redis URL -->
    <v-row>
      <v-col cols="12">
        <v-card class="redis-url-card">
          <v-card-title class="detail-card-title">Redis URL</v-card-title>
          <v-card-text>
            <va-button outlined rounded color="primary" class="redis-url-button" @click="copyToClipboard">{{ redisUrl }}</va-button>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      region: 'us-east-1',
      endpoint: 'us1-handy-mayfly-38460.upstash.io',
      password: '•••••••••',
      port: '38460',
      tlsEnabled: true
    };
  },
  computed: {
    redisUrl() {
      return `redis://default:${this.password}@${this.endpoint}:${this.port}`;
    }
  },
  methods: {
    copyToClipboard() {
      // Copy the Redis URL to clipboard
      // Implement the clipboard copy functionality as per your preference
      // This can be achieved using external libraries or the Clipboard API
    }
  }
};
</script>

<style scoped>
.data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.data-item {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 8px;
}

.data-label {
  font-weight: bold;
  margin-right: 4px;
  color: #424242;
}

.data-value {
  color: #616161;
}

.redis-url-card {
  text-align: center;
  padding: 16px;
}

.redis-url-button {
  width: 100%;
  max-width: 400px;
  word-break: break-all;
}

@media (max-width: 600px) {
  .data-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .data-item {
    margin-right: 0;
    margin-bottom: 16px;
  }

  .redis-url-button {
    max-width: unset;
    width: auto;
  }
}
</style>
